import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { Card } from '@components/Card';
import { Button } from '@components/Button';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "card"
    }}>{`Card`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Card} mdxType="Props" />
    <h2 {...{
      "id": "card-1"
    }}>{`Card`}</h2>
    <Playground __position={1} __code={'<Card\n  title=\"Constantin Predescu\"\n  subtitle=\"25 matches\"\n  user={{\n    name: \'Marcel Popescu\',\n    profilePicture: \'https://picsum.photos/200\',\n    gender: {\n      name: \'male\',\n    },\n  }}\n  action={<Button title=\"Follow\" rounded size=\"small\" />}\n  onClose={() => console.log(\'press x\')}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Card,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Card title="Constantin Predescu" subtitle="25 matches" user={{
        name: 'Marcel Popescu',
        profilePicture: 'https://picsum.photos/200',
        gender: {
          name: 'male'
        }
      }} action={<Button title="Follow" rounded size="small" mdxType="Button" />} onClose={() => console.log('press x')} mdxType="Card" />
    </Playground>
    <h2 {...{
      "id": "with-active"
    }}>{`with Active`}</h2>
    <Playground __position={2} __code={'<View style={{ flexDirection: \'row\', justifyContent: \'space-between\' }}>\n  <Card\n    title=\"Constantin Predescu\"\n    subtitle=\"25 matches\"\n    active\n    user={{\n      name: \'Marcel Popescu\',\n      profilePicture: \'https://picsum.photos/200\',\n      gender: {\n        name: \'male\',\n      },\n    }}\n  />\n  <Card\n    title=\"Constantin Predescu\"\n    subtitle=\"25 matches\"\n    user={{\n      name: \'Marcel Popescu\',\n      profilePicture: \'https://picsum.photos/200\',\n      gender: {\n        name: \'male\',\n      },\n    }}\n    action={\n      <Button\n        title=\"Been there?\"\n        rounded\n        size=\"small\"\n        type=\"outline\"\n        buttonStyle={{ paddingHorizontal: 5 }}\n      />\n    }\n  />\n  <Card\n    title=\"Constantin Predescu\"\n    subtitle=\"25 matches\"\n    user={{\n      name: \'Marcel Popescu\',\n      profilePicture: \'https://picsum.photos/200\',\n      gender: {\n        name: \'male\',\n      },\n    }}\n    action={\n      <Button\n        title=\"Following\"\n        rounded\n        size=\"small\"\n        type=\"outline\"\n        buttonStyle={{ paddingHorizontal: 5 }}\n      />\n    }\n  />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Card,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between'
      }} mdxType="View">
    <Card title="Constantin Predescu" subtitle="25 matches" active user={{
          name: 'Marcel Popescu',
          profilePicture: 'https://picsum.photos/200',
          gender: {
            name: 'male'
          }
        }} mdxType="Card" />
    <Card title="Constantin Predescu" subtitle="25 matches" user={{
          name: 'Marcel Popescu',
          profilePicture: 'https://picsum.photos/200',
          gender: {
            name: 'male'
          }
        }} action={<Button title="Been there?" rounded size="small" type="outline" buttonStyle={{
          paddingHorizontal: 5
        }} mdxType="Button" />} mdxType="Card" />
    <Card title="Constantin Predescu" subtitle="25 matches" user={{
          name: 'Marcel Popescu',
          profilePicture: 'https://picsum.photos/200',
          gender: {
            name: 'male'
          }
        }} action={<Button title="Following" rounded size="small" type="outline" buttonStyle={{
          paddingHorizontal: 5
        }} mdxType="Button" />} mdxType="Card" />
  </View>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      